<template>
  <div>
    <v-data-table :loading="loadingTable" :headers="headers" :items="reserves" sort-by="calories" class="elevation-1"
      :page-count="numberOfPagesPaginate" :page="currentPagePaginate" :server-items-length="totalStagesPaginate"
      v-model="selectedItens" @click:row="selectItem"
      :options.sync="options" :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true,
      }">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Reservas Efetivadas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field class="mr-2" placeholder="Proucurar ID, Hóspede, Empresa, Solicitante ou Localizador externo" v-model="search"
            :append-icon="icons.mdiMagnify" single-line label="Localizar" outlined filled rounded dense hide-details
            @click:append="searchItems()" @change="searchItems()"></v-text-field>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <template v-slot:item.guests="{ item }">
        {{ allGuests(item.accommodation[0].guest) }}
      </template>

      

      <template v-slot:item.status="{ item }">
        <v-chip color="info" v-if="item.status == 'confirmed'">
          {{ reserved(item) }}
        </v-chip>
        <v-chip color="success" v-if="item.status == 'checkin'">
          {{ reserved(item) }}
        </v-chip>
        <v-chip color="error" v-if="item.status == 'checkout'">
          {{ reserved(item) }}
        </v-chip>
        <v-chip color="secondary" v-if="item.status == 'canceled'">
          {{ reserved(item) }}
        </v-chip>
        <v-chip color="warning" v-if="item.status == 'no-show'">
          {{ reserved(item) }}
        </v-chip>
      </template>
      <template v-slot:item.room="{ item }">
        
      </template>
      <template v-slot:item.check_in="{ item }">
        {{ formatDate(item.check_in) }}
      </template>
      <template v-slot:item.check_out="{ item }">
        {{ formatDate(item.check_out) }}
      </template>
      <template v-slot:item.actions="{ item }">
       

      </template>

      <template v-slot:no-data>
        <NoDataTable></NoDataTable>
      </template>
      <template v-slot:loading>
        Carregando...
      </template>
    </v-data-table>

    <v-card class="mt-2">
      <v-card-item>
        <v-card-subtitle>
          Clique no registro para visualizar a observação:
        </v-card-subtitle>
      </v-card-item>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="text-center">
            <p v-text="selectedItens.length>0 ?selectedItens[0].obs:''"></p>
          </v-col>
        </v-row>
      </v-card-text>      
    </v-card>
  </div>
</template>

<script>
// import MsgDelete from '@/components/MsgDelete.vue'
import NoDataTable from '@/components/NoDataTable.vue'
import {
  mdiClose,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiPlus,
  mdiCloseCircleOutline,
  mdiPrinter,
  mdiCurrencyUsd,
  mdiFilter,
  mdiMessageOutline
} from '@mdi/js'
import { mapActions, mapMutations } from 'vuex'
import DialogReserve from './_components/DialogReserve.vue'
import BarTitleDialog from '@/components/BarTitleDialog.vue'

export default {
  components: {
    NoDataTable,
    DialogReserve,
    BarTitleDialog,
  },
  data: () => ({
    selectedItens: [],
    loadingUserAdmin: false,
    userAdmin: false,
    cancelCashId: null,
    loadingOpenedCashs: false,
    cashItems: [],
    loadingCancel: false,
    justifyCancel: '',
    dialogCancel: false,
    dialogFilter: false,
    filter: {},
    search: '',
    dialogNewGuest: false,
    loadingTable: false,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    dialogDelete: false,
    dialog: false,
    loading: false,
    itemsStatus: [
      { text: 'Checkin', value: 'checkin' },
      { text: 'Checkout', value: 'checkout' },
      { text: 'Cancelado', value: 'canceled' },
      { text: 'Confirmado', value: 'confirmed' },
      { text: 'No-Show', value: 'no-show' },
    ],
    headers: [
      {
        text: 'ID',
        align: 'start',
        value: 'id',
      },
      {
        text: 'EMPRESA',
        value: 'company.fantasy_name',
        sortable: false,
      },
    
      {
        text: 'HÓSPEDES',
        value: 'guests',
        sortable: false,
      },
    
      {
        text: 'QUARTO',
        value: 'accommodation[0].room.description',
        sortable: false,
      },
      {
        text: 'CATEGORIA',
        value: 'accommodation[0].room_category.description',
        sortable: false,
      },
      {
        text: 'CHECKIN',
        value: 'check_in',
        sortable: false,
      },
      {
        text: 'CHECKOUT',
        value: 'check_out',
        sortable: false,
      },
      {
        text: 'STATUS',
        value: 'status',
        sortable: false,
      },
    ],
    reserves: [],
    editedIndex: -1,
    editedItem: {},
    editedDefault: {
      check_in: '',
      check_out: '',
      company_id: '',
      room: [],
      tariff_manual: 'not',
      with_iss: 'not',
    },
    options: {},
    tab: null,
    items: [
      {
        tab: 'One',
        content: 'Tab 1 Content',
      },
      {
        tab: 'Two',
        content: 'Tab 2 Content',
      },
    ],
    icons: {
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiClose,
      mdiCloseCircleOutline,
      mdiPrinter,
      mdiCurrencyUsd,
      mdiFilter,
      mdiMessageOutline
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nova Reserva' : 'Editar Reserva'
    },
    bagFilter() {
      if (this.filter.status == null) {
        delete this.filter.status
      }
      return Object.keys(this.filter).length
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    options: {
      handler() {
        this.initialize()
      },
    },
  },

  created() {
    this.initialize()
    //carrega o select rooms do dialog reserve
    this.loadRooms()
    //carrega o select categoria de quartos do dialog reserve
    this.loadRoomCategories()
    //carrega o select tariffs
    this.loadTariffs()
  },

  methods: {
    
    ...mapActions('reserve', [
      'getAll',
      'store',
      'update',
      'printVoucher',
      'getReserveItems',
      'cancelReserve',
      'getOpenedCashs',
      'getUser',
      'getCompletedReserve'
    ]),
    
    initialize() {
      this.loadingTable = true
      this.selectedItens = []
      const { page, itemsPerPage } = this.options

      const payload = {
        itemsPerPage: itemsPerPage || 10,
        page,
        search: this.search,
      }
      this.getCompletedReserve(payload)
        .then(response => {
          this.reserves = response.data.data
          this.totalStagesPaginate = response.data.total
          this.numberOfPagesPaginate = response.data.last_page
          this.currentPagePaginate = response.data.current_page
        })
        .finally(() => {
          this.loadingTable = false
        })
    },
    searchItems() {
      this.options = {}
      this.initialize()
    },
    requester(item) {
      if (item.requester == null) {
        if (item.company) {
          return item.company.fantasy_name
        } else if (item.guest.length > 0) {
          return item.guest[0].name
        } else if (item.guest_unknown.length > 0) {
          return item.guest_unknown[0].name
        }
      } else {
        return item.requester
      }
    },
    
    firstGuest(item) {
      if (item.guest.length > 0) {
        return item.guest[0].name
      } else if (item.guest_unknown.length > 0) {
        return item.guest_unknown[0].name
      }
      return ''
    },

    allGuests(item) {
      let guests = []
      item.forEach(guest => {
        guests.push(guest.name)
      })

      return guests.join(', ')
    },
    
    reserved(item) {
      switch (item.status) {
        case 'confirmed':
          return 'Confirmado'
        case 'checkin':
          return 'Checkin'
        case 'checkout':
          return 'Checkout'
        case 'canceled':
          return 'Cancelado'
        case 'no-show':
          return 'No-Show'
      }
    },

    printItem(reserve) {
      reserve.loading_print = true
      this.printVoucher(reserve)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'voucher.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => {
          this.reserves.forEach(item => {
            item.loading_print = false
          })
          //armegagem para força a reação do vue
          let backup = this.reserves
          this.reserves = []
          this.reserves = backup
        })
    },
    
    formatDate(date) {
      if (date) {
        const result = new Date(date)
        return result.toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
        })
      }
    },
    selectItem(item) {
      this.selectedItens = []
      this.selectedItens.push(item)
      console.log(this.selectedItens)
    },
    
  },
}
</script>

<style scoped>
.card-tab {
  min-height: 420px;
  min-width: 736px;
}

.tool_table_fam {
  height: 50px;
}
</style>
