var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loadingTable,"headers":_vm.headers,"items":_vm.reserves,"sort-by":"calories","page-count":_vm.numberOfPagesPaginate,"page":_vm.currentPagePaginate,"server-items-length":_vm.totalStagesPaginate,"options":_vm.options,"footer-props":{
      itemsPerPageText: 'Itens por pagina',
      itemsPerPageOptions: [5, 10, 15],
      showFirstLastPage: true,
    }},on:{"click:row":_vm.selectItem,"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Reservas Efetivadas")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{staticClass:"mr-2",attrs:{"placeholder":"Proucurar ID, Hóspede, Empresa, Solicitante ou Localizador externo","append-icon":_vm.icons.mdiMagnify,"single-line":"","label":"Localizar","outlined":"","filled":"","rounded":"","dense":"","hide-details":""},on:{"click:append":function($event){return _vm.searchItems()},"change":function($event){return _vm.searchItems()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1)]},proxy:true},{key:"item.guests",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.allGuests(item.accommodation[0].guest))+" ")]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [(item.status == 'confirmed')?_c('v-chip',{attrs:{"color":"info"}},[_vm._v(" "+_vm._s(_vm.reserved(item))+" ")]):_vm._e(),(item.status == 'checkin')?_c('v-chip',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.reserved(item))+" ")]):_vm._e(),(item.status == 'checkout')?_c('v-chip',{attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.reserved(item))+" ")]):_vm._e(),(item.status == 'canceled')?_c('v-chip',{attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.reserved(item))+" ")]):_vm._e(),(item.status == 'no-show')?_c('v-chip',{attrs:{"color":"warning"}},[_vm._v(" "+_vm._s(_vm.reserved(item))+" ")]):_vm._e()]}},{key:"item.room",fn:function(ref){
    var item = ref.item;
return undefined}},{key:"item.check_in",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.check_in))+" ")]}},{key:"item.check_out",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.check_out))+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return undefined}},{key:"no-data",fn:function(){return [_c('NoDataTable')]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Carregando... ")]},proxy:true}]),model:{value:(_vm.selectedItens),callback:function ($$v) {_vm.selectedItens=$$v},expression:"selectedItens"}}),_c('v-card',{staticClass:"mt-2"},[_c('v-card-item',[_c('v-card-subtitle',[_vm._v(" Clique no registro para visualizar a observação: ")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('p',{domProps:{"textContent":_vm._s(_vm.selectedItens.length>0 ?_vm.selectedItens[0].obs:'')}})])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }